<script lang="ts">
  import Button from "svelma/src/components/Button.svelte";
  import Notification from "svelma/src/components/Notification/index";

  import type { AuthObject } from "@/app";

  import { auth, fbFunctions, isUser as isDbUser, logOut } from "@/lib";
  import { httpsCallable } from "firebase/functions";
  import type { Language } from "@/stores/i18n";
  import { authStore } from "@/stores";

  let currentUser: AuthObject["user"] = auth.currentUser;
  let isUser = false;

  authStore.subscribe(async (auth) => {
    currentUser = auth.user;
    isUser = await isDbUser(auth.user?.email);
  });


  // Icon activation
  const generateWiktionaryJson = httpsCallable<{ language: Language }, null>(
    fbFunctions,
    "generateWiktionaryJson",
    { timeout: 120000 }
  );

  let publishing = false;
  async function publish() {
    publishing = true;

    for (const language of ["nl", "be", "en", "es", "de"] as Language[]) {
      try {
        await generateWiktionaryJson({ language });
        Notification.create({
          message: "Gepubliceerd: " + language.toUpperCase(),
          type: "is-success",
          position: "is-bottom-right",
          autoClose: false,
        });
      } catch (error) {
        console.error("error publishing", error);
        Notification.create({
          message: "Mislukt: " + language.toUpperCase(),
          type: "is-danger",
          position: "is-bottom-right",
          autoClose: false,
        });
      }
    }
    publishing = false;
  }
</script>

<header>
  <nav class="navbar" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">Puzzle Studio - Words</a>
    </div>
    <div class="navbar-menu">
      {#if isUser}
        <div class="navbar-start">
          <a class="navbar-item" href="/words/info" title="Woordenlijsten">Woordenlijsten</a>
          <a class="navbar-item" href="/admin/config" title="Admin">Admin</a>
        </div>
      {/if}
      {#if currentUser}
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="navbar-buttons">
              <Button
                iconLeft="upload"
                type="is-primary mr-5"
                loading={publishing}
                on:click|once={publish}>Publiceer woorden</Button
              >
              <span class="username">{currentUser.email}</span>
              <button class="log-out-button" on:click={logOut}>(Log uit)</button>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </nav>
</header>

<style lang="scss" scoped>
  .navbar {
    margin-left: 4.5rem;
    border-bottom: 1px solid #eaecef;
    @media screen and (min-width: 1023px) {
      padding: 0 12px;
    }
  }

  .navbar-menu {
    align-items: center;
    display: flex;
  }

  .sr-label {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }

  .log-out-button {
    appearance: none;
    background: none;
    border: none;
    color: #485fc7;
    cursor: pointer;
    font: inherit;
    font-size: 1rem;
    padding: 0;
    &:hover {
      color: #363636;
    }
  }

  @media screen and (max-width: 1023px) {
    .navbar-menu {
      background-color: #fff;
      box-shadow: 0 8px 16px #0a0a0a1a;
      padding: 0.5rem 0;
    }
  }

  .navbar-brand {
    font-size: 1.5rem;
    font-weight: 500;
  }
</style>
