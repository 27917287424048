<script lang="ts">
  import { digitalUrl, inboxUrl, printUrl } from "@/lib";
  import Icon from "svelma/src/components/Icon.svelte";
  interface IStudioItem {
    active?: boolean;
    icon: string;
    label: string;
    link?: string;
    style?: string;
  }

  const studio: IStudioItem[] = [
    { icon: 'display', label: 'Digital', link: digitalUrl },
    { icon: 'newspaper', label: 'Print', link: printUrl, style: 'regular' },
    { icon: 'inbox', label: 'Content', link: inboxUrl },
    { icon: 'file-lines', label: 'Words', link: "/", style: 'regular', active: true },
    {
      icon: 'comment-dots',
      label: 'Feedback',
      style: 'regular',
    },
  ];
</script>

<div class="studio-bar">
  <nav aria-label="studio navigation">
    <a class="logo" href="/">
      <img src="/images/42puzzles-logo.svg" alt="42puzzles logo" />
    </a>

    <div class="links">
      {#each studio as { active, icon, label, link, style }}      
        <a
          aria-label="label"
          class="navbar-item"
          class:active={active}
          class:disabled={!link}
          href={link}
          >
          <Icon icon={icon} class={style ? 'fa-' + style : ''} />
          <span class="label">{ label }</span>
        </a>
        {/each}
    </div>
  </nav>
</div>

<style lang="scss" scoped>
  $primary-black: #000;
  $primary-blue: rgb(72, 95, 199);
  $primary-white: #fff;
  $secondary-white: rgb(250, 250, 250);
  
  $primary-opacity: 0.5;
  $default-radius: 10px;
  
  .studio-bar {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 4.5rem;
    background-color: $primary-blue;
    
    nav {
      flex: 1 1 0%;
      padding-left: 0.5rem;
    }
    
    .logo {
      display: flex;
      padding: 0.5rem;
      padding-left: 0;
    }
    
    .links {
      margin-top: 2rem;
    }
    
    .navbar-item {
      flex-direction: column;
      justify-content: center;
      color: white;
      gap: 0.25rem;
      font-size: 1.25rem;
      border-top-left-radius: $default-radius;
      border-bottom-left-radius: $default-radius;
      padding: 0 0 7px 0;
    
      :global(.icon) {
        margin: 8px 20px 0px 15px;
      }
    
      .label {
        color: $primary-white;
        font-size: 0.75rem;
        font-weight: 500;
        text-align: center;
      }
    
      &.active {
        pointer-events: none;
      }
    
      &.disabled {
        pointer-events: none;
        opacity: $primary-opacity;
      }
    
      &.active {
        background-color: $primary-white;
        color: black;

        > * {
          color: black;
        }
      }
    
      &:hover {
        background-color: $secondary-white;
        opacity: $primary-opacity;
        color: black;
    
        > * {
          color: black;
        }
      }
    }
    
    .navbar-item.active,
    .navbar-item:hover {
      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: transparent;
        right: 0;
        bottom: calc($default-radius * -2);
        height: calc($default-radius * 2);
        width: $default-radius;
        border-top-right-radius: $default-radius;
      }
    
      &::before {
        top: calc($default-radius * -2);
        bottom: 0;
        transform: rotate3d(1, 0, 0, 180deg);
      }
    }
    
    .navbar-item {
      &.active {
        &::before,
        &::after {
          box-shadow: 0 calc($default-radius * -1) 0 0 $primary-white;
        }
      }
    
      &:hover {
        &::before,
        &::after {
          box-shadow: 0 calc($default-radius * -1) 0 0 $secondary-white;
        }
      }
    }
  }
  </style>
  