import type { AuthObject } from "@/app";

import { goto } from "$app/navigation";

/**
 * Guard the routes from unauthorized users
 * @param AuthObject auth state from the store
 * @param pathname pathname from the current page
 * @param isAdmin is the user an registered admin
 */
export async function guardRoutes({ isLoggedIn, user }: AuthObject): Promise<void> {
  const isNotLoggedIn = user !== undefined && !isLoggedIn;

  if (isNotLoggedIn) {
    await goto("/login");
  }
}
