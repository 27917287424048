<script lang="ts">
  import "bulma/css/bulma.css";
  import "@fortawesome/fontawesome-free/css/all.css";

  import type { AuthObject } from "@/app";

  import Spinner from "@/components/Spinner.svelte";
  import NavBar from "@/components/NavBar.svelte";
  import { onMount } from "svelte";
  import { library, dom } from "@fortawesome/fontawesome-svg-core";
  import { fab } from "@fortawesome/free-brands-svg-icons";
  import { auth, authListener, guardRoutes, isUser as isDbUser } from "@/lib";
  import { authStore } from "@/stores";
  import StudioBar from "@/components/StudioBar.svelte";

  let loaded = false;
  let currentUser: AuthObject["user"] = auth.currentUser;
  let isLoggedIn = false;

  // Icon activation
  library.add(fab);
  dom.watch();

  onMount(async () => {
    await authListener();

    authStore.subscribe(async (auth) => {
      currentUser = auth.user;
      isLoggedIn = auth.isLoggedIn;

      await guardRoutes(auth);

      loaded = !!(auth.user !== undefined);
    });
  });
</script>

{#if loaded}
  {#if currentUser && isLoggedIn}
    <StudioBar />
    <NavBar />
  {/if}
  <div class="container">
    <slot />
  </div>
{:else}
  <Spinner />
{/if}

<style lang="scss" scoped>
  .container {
    margin-let: 4.5rem;
  }
  
</style>
